import * as React from 'react';
import {Link} from 'gatsby';
import { Link as GoLink } from 'react-scroll';
import Image from './Image';

const Footer = () => (
    <>
      <section className="contact">
        <Link to="/contact/" className="title small">
          CONTACT<span>お問い合わせ・資料請求</span>
        </Link>
      </section>
      <footer>
        <div className="footer">
          <div className="footer-top">
            <div className="logo">
              <div className="andersen text-center">
                <Link className="md:hover:opacity-70 transition-opacity block" to="/">
                  <Image className="mx-auto" filename="logo/andersen.png" alt="アンデルセンストーブ" width={300} />
                  <p className="mt-4">薪ストーブ 日本総代理店の<br className="sp" />アンデルセンストーブ</p>
                </Link>
              </div>
              <div className="other">
                <div className="w-1/2">
                  <Link className="md:hover:opacity-70 transition-opacity block" to="/brand/morso/">
                    <Image filename="logo/morso.png" width={150} alt="morso" />
                  </Link>
                </div>
                <div className="w-1/2">
                  <Link className="md:hover:opacity-70 transition-opacity block" to="/brand/leda/">
                    <Image filename="logo/leda.png" width={200} alt="leda" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="link">
              <ul>
                <li><Link className="hover-link" to="/chimney/">煙突</Link></li>
                <li><Link className="hover-link" to="/accessory/">アクセサリ</Link></li>
                <li><Link className="hover-link" to="/beginner/">初めての方へ</Link></li>
                <li><Link className="hover-link" to="/works/">導入事例</Link></li>
              </ul>
              <ul>
                <li><Link className="hover-link" to="/movie/">MOVIE</Link></li>
                <li><Link className="hover-link" to="/showroom/">ショールーム</Link></li>
                <li><Link className="hover-link" to="/agencies/">正規代理店</Link></li>
              </ul>
            </div>
          </div>
          <ul className="footer-middle">
            <li><Link className="hover-link gray" to="/news/">お知らせ</Link></li>
            <li><Link className="hover-link gray" to="/company/">会社概要</Link></li>
            <li><Link className="hover-link gray" to="/faq/">よくある質問</Link></li>
            <li><Link className="hover-link gray" to="/privacy/">プライバシーポリシー</Link></li>
            <li><a className="hover-link gray" href="http://www.andersenstove-members.info/" target="_blank" rel="noreferrer">ディーラーサイト</a></li>
          </ul>
          <div className="footer-bottom">
            <p className="copy">Copyright &copy; SHINGUSHOKO All right reserved.</p>
          </div>
        </div>
        <GoLink className="go-to-top vertical" to="pageTop" spy smooth duration={800}>
          <p>SCROLL TO TOP</p>
        </GoLink>
      </footer>
    </>
);

export default Footer;

import * as React from 'react';
import {Link} from 'gatsby';
import Nav from './nav';
import Sns from './list-sns';
import Image from './Image';

const Header = ({ setHbgOpen, hbgOpen }) => {
  const hbgClick = (e) => {
    if (hbgOpen) {
      setHbgOpen(false);
    } else {
      setHbgOpen(true);
    }
    e.preventDefault();
  };
  return (
    <>
      <header className="header">
        <p className="header-ttl">
          <a href="https://www.shingu-shoko.co.jp/" target="_blank" rel="noreferrer">
            <Image filename="logo/shingu.png" width={160} alt="shingu" />
          </a>
        </p>
        <a
            href="/"
            className={`hbg ${hbgOpen ? 'open' : ''}`}
            id="js-hbg"
            onClick={hbgClick}
        >
          <div className="wrap">
            <span />
            <span />
          </div>
        </a>
        <div className="hbg-bg" />
        <div className={`nav-wrap ${hbgOpen ? 'open' : ''}`}>
          <p className="title large vertical">PRODUCTS</p>
          <Nav />
        </div>
        <div className="sns-area">
          <Sns />
        </div>
        <Link className="contact-btn" to="/contact/">
          <Image filename="ico/mail.png" width={29} alt="お問い合わせ" />
          <p className="pc">お問い合わせ<br />資料請求</p>
        </Link>
      </header>
    </>
  );
};

export default Header;

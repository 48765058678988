/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useState, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import SearchModal from './modal';

const Layout = ({ children, page }) => {
  const [hbgOpen, setHbgOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [pageSlug, setPageSlug] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    setPageSlug(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={page}>
        <Header
          siteTitle={data.site.siteMetadata.title || 'Title'}
          hbgOpen={hbgOpen}
          setHbgOpen={setHbgOpen}
        />
        <main id="pageTop" className={`${hbgOpen ? 'open' : ''} ${modalOpen ? 'modal-open' : ''}`}>
          <SearchModal modalOpen={modalOpen} setModalOpen={setModalOpen}/>
          {children}
        </main>
        <Footer pageSlug={pageSlug} />
      </div>
    </>
  );
};

export default Layout;

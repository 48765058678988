import {Link} from 'gatsby';
import * as React from 'react';
import Image from './Image';
import Sns from './list-sns';

const Nav = () => (
      <>
        <nav className="nav">
          <div className="nav-block">
            <p className="title small">TOP</p>
            <ul>
              <li>
                <Link className="hover-link" to="/">トップページ</Link>
              </li>
            </ul>
          </div>
          <div className="nav-block">
            <p className="title small">PRODUCTS</p>
            <ul>
              <li>
                <Link className="hover-link" to="/brand/">ブランド一覧</Link>
              </li>
              <li>
                <Link className="hover-link" to="/chimney/">煙突</Link>
              </li>
              <li>
                <Link className="hover-link" to="/accessory/">アクセサリ</Link>
              </li>
            </ul>
          </div>
          <div className="nav-block">
            <p className="title small">HOW TO</p>
            <ul>
              <li>
                <Link className="hover-link" to="/beginner/">初めての方へ</Link>
              </li>
              <li>
                <Link className="hover-link" to="/woodstove/">薪ストーブとは</Link>
              </li>
              <li>
                <Link className="hover-link" to="/works/">導入事例</Link>
              </li>
              <li>
                <Link className="hover-link" to="/movie/">動画紹介</Link>
              </li>
            </ul>
          </div>
          <div className="nav-block">
            <p className="title small">SHOWROOM</p>
            <ul>
              <li>
                <Link className="hover-link" to="/showroom/">ショールーム</Link>
              </li>
              <li>
                <Link className="hover-link" to="/agencies/">正規代理店</Link>
              </li>
            </ul>
          </div>
          <div className="nav-bottom">
            <ul>
              <li>
                <Link className="hover-link gray" to="/news/">お知らせ</Link>
              </li>
              <li>
                <Link className="hover-link gray" to="/company/">会社概要</Link>
              </li>
              <li>
                <Link className="hover-link gray" to="/faq/">よくある質問</Link>
              </li>
              <li>
                <Link className="hover-link gray" to="/privacy/">プライバシーポリシー</Link>
              </li>
              <li>
                <a className="hover-link gray" href="http://www.andersenstove-members.info/" target="_blank" rel="noreferrer">ディーラーサイト</a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="nav-sns">
          <Sns/>
        </div>
        <div className="brand">
          <Link className="contact-btn md:hover:opacity-70 transition-opacity block" to="/contact/">
            <Image filename="ico/mail.png" width={29} alt="お問い合わせ" />
            <p className="pc">お問い合わせ<br />資料請求</p>
          </Link>
          <Link className="morso md:hover:opacity-70 transition-opacity block" to="/brand/morso/">
            <Image filename="logo/morso.png" width={120} alt="morso" />
          </Link>
          <Link className="leda md:hover:opacity-70 transition-opacity block" to="/brand/leda/">
            <Image filename="logo/leda.png" width={120} alt="leda" />
          </Link>
          <Link className="pdf md:hover:opacity-70 transition-opacity block" to="/catalog/">
            <Image filename="ico/pdf.png" width={40} alt="PDFカタログ" />
            <p>カタログ一覧</p>
          </Link>
        </div>
      </>
  );

export default Nav;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'gatsby';
import Image from './Image';

const SearchModal = ({ setModalOpen, modalOpen }) => {
  const modalClick = (e) => {
    if (modalOpen) {
      setModalOpen(false);
    } else {
      setModalOpen(true);
    }
    e.preventDefault();
  };
  const [ledaItemList, setLedaItemList] = useState('');
  const [morsoItemList, setMorsoItemList] = useState('');
  // Ajax設定
  const ledaItemJson = {
    method: 'get',
    url: '/update/brand/leda/index.json',
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  const morsoItemJson = {
    method: 'get',
    url: '/update/brand/morso/index.json',
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(ledaItemJson)
    .then((response) => {
      setLedaItemList(response.data);
    })
    .catch(() => {
      // console.log(error);
    });
    axios(morsoItemJson)
    .then((response) => {
      setMorsoItemList(response.data);
    })
    .catch(() => {
      // console.log(error);
    });
  };
  // List生成
  const morsoItemListArray = (items) => (
      <>
        {items.map((item) => (
            <li className="md:w-3/12 w-1/2 md:px-4 px-2" key={item.title}>
              <Link className="md:hover:opacity-70 transition-opacity block" to={`/brand/morso/detail/?id=${item.title}`} onClick={() => setModalOpen(false)}>
                <img
                    className="full-image"
                    src={`/update/brand/morso/${item.title}/main.png`}
                    alt=""
                />
                <p>{item.title}<span>{item.ordering ? <span className=""> / {item.ordering}</span>: null}</span></p>
              </Link>
            </li>
        ))}
      </>
  );
  const ledaItemListArray = (items) => (
      <>
        {items.map((item) => (
            <li className="md:w-3/12 w-1/2 md:px-4 px-2" key={item}>
              <Link className="md:hover:opacity-70 transition-opacity block" to={`/brand/leda/detail/?id=${item}`} onClick={() => setModalOpen(false)}>
                <img
                    className="full-image"
                    src={`/update/brand/leda/${item}/main.png`}
                    alt=""
                />
                <p>{item}</p>
              </Link>
            </li>
        ))}
      </>
  );
  // Event
  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <section className="search-modal">
        <a
            href="/"
            className={`modal-btn${modalOpen ? ' open' : ''}`}
            onClick={modalClick}
        >
          <Image filename="common/modal-btn.png" />
        </a>
        <div
             className={`modal-content text-white${modalOpen ? ' open' : ''}`}
        >
          <a
              href="/"
              className="close-btn"
              onClick={modalClick}
          >
            <span>CLOSE</span>
          </a>
          <div className="modal-content-link">
            <div className="md:flex justify-end items-end">
              <div>
                <p className="cat futura pc"><span>STOVE</span></p>
                <div className="flex futura">
                  <a className="md:hover:opacity-70 transition-opacity" href="#morso">MORSO</a>
                  <a className="md:hover:opacity-70 transition-opacity scroll" href="#leda">LEDA</a>
                </div>
              </div>
              <div className="center">
                <div className="flex futura">
                  <a className="md:hover:opacity-70 transition-opacity" href="#chimney">煙突</a>
                  <a className="md:hover:opacity-70 transition-opacity" href="#accessory">アクセサリ</a>
                </div>
              </div>
              <div className="c-yellow">
                <p className="cat futura pc"><span>SEARCH</span></p>
                <div className="flex futura">
                  <a className="md:hover:opacity-70 transition-opacity" href="#area">暖房面積から探す</a>
                  <a className="md:hover:opacity-70 transition-opacity" href="#size">サイズから探す</a>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-content-inner">
            <div id="morso" className="md:flex items-center">
              <Link className="category-image md:hover:opacity-70 transition-opacity" to="/brand/morso/lineup/">
                <Image className="full-image" filename="logo/morso.png"/>
              </Link>
              <p className="md:pl-12">アンデルセンストーブのフラグシップブランドとして長年日本で販売されているデンマークのブランド。<br />
                デンマーク王室御用達の称号を受けており創業160年以上の伝統と最新鋭の技術を兼ね備えたストーブを多数ラインナップしています。</p>
            </div>
            <div className="mt-6 md:px-6">
              <ul className="flex flex-wrap md:-mx-4 -mx-2 item-list">
                {morsoItemList
                    ? morsoItemList.map((item) => (
                        morsoItemListArray(item.items)
                    ))
                    : null}
              </ul>
            </div>
            <div id="leda" className="md:flex items-center md:mt-20 mt-12">
              <Link className="category-image md:hover:opacity-70 transition-opacity" to="/brand/leda/lineup/">
                <Image className="full-image" filename="logo/leda.png"/>
              </Link>
              <p className="md:pl-12">工業大国で認められた品質と環境先進国の厳しい排出ガス基準をクリアしたモデルをラインナップしています。<br />
                鋳物製造から組み立てまで自社で一貫しておこなっておりドイツ国内の鋳物ストーブシェアNo.1の実績です。</p>
            </div>
            <div className="mt-6 md:px-6">
              <ul className="flex flex-wrap md:-mx-4 -mx-2 item-list">
                {ledaItemList
                    ? ledaItemList.map((item) => (
                        ledaItemListArray(item.items)
                    ))
                    : null}
              </ul>
            </div>
            <div id="chimney" className="md:flex items-center md:mt-20 mt-12">
              <Link className="category-image md:hover:opacity-70 transition-opacity" to="/chimney/">
                <Image className="full-image" filename="logo/chimney.png"/>
              </Link>
              <p className="md:pl-12">新宮商行が日本市場で積み重ねてきたノウハウのすべてと、日本特有のニーズを織り込み、モノづくり日本ならではの技術を駆使して開発した、SCS匠 断熱二重煙突です。</p>
            </div>
            <div className="mt-6 md:px-6">
              <ul className="flex flex-wrap md:-mx-4 -mx-2 item-list">
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-1.jpg"
                  />
                  <p>直筒 1000L/500L/300L/150L</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-2.jpg"
                  />
                  <p>直筒アジャスター</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-3.jpg"
                  />
                  <p>スペシャルスライド</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-4.jpg"
                  />
                  <p>T型90°</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-5.jpg"
                  />
                  <p>十字曲がり</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-6.jpg"
                  />
                  <p>直筒300Lダンパー付</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-7.jpg"
                  />
                  <p>ロッキングバンド</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-8.jpg"
                  />
                  <p>エルボ（30°,45°,90°）</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-9.jpg"
                  />
                  <p>ウォールサポートベース<br />ウォールサポートベースSTD</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-10.jpg"
                  />
                  <p>スライドアダプターS+W</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Image
                      className="full-image"
                      filename="common/chimney-11.jpg"
                  />
                  <p>変換アダプター</p>
                </li>
              </ul>
            </div>
            <div id="accessory" className="md:flex items-center mt-20">
              <Link className="category-image md:hover:opacity-70 transition-opacity" to="/accessory/">
                <span className="futura text-black text-2xl">ACCESSORIES</span>
              </Link>
              <p className="md:pl-12">新宮商行が日本市場で積み重ねてきたノウハウのすべてと、日本特有のニーズを織り込み、モノづくり日本ならではの技術を駆使して開発した、SCS匠 断熱二重煙突です。</p>
            </div>
            <div className="mt-6 md:px-6">
              <ul className="flex flex-wrap md:-mx-4 -mx-2 item-list">
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Link className="md:hover:opacity-70 transition-opacity" to="/accessory/detail/?id=outdoor" onClick={() => setModalOpen(false)}>
                    <Image className="full-image" filename="common/accessory-cat-01.jpg" />
                  </Link>
                  <p className="futura mt-2">OUTDOOR</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Link className="md:hover:opacity-70 transition-opacity" to="/accessory/detail/?id=cooking" onClick={() => setModalOpen(false)}>
                    <Image className="full-image" filename="common/accessory-cat-02.jpg" />
                  </Link>
                  <p className="futura mt-2">COOKING</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Link className="md:hover:opacity-70 transition-opacity" to="/accessory/detail/?id=daily-use" onClick={() => setModalOpen(false)}>
                    <Image className="full-image" filename="common/accessory-cat-03.jpg" />
                  </Link>
                  <p className="futura mt-2">DAILY USE</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Link className="md:hover:opacity-70 transition-opacity" to="/accessory/detail/?id=maintenance" onClick={() => setModalOpen(false)}>
                    <Image className="full-image" filename="common/accessory-cat-04.jpg" />
                  </Link>
                  <p className="futura mt-2">MAINTENANCE</p>
                </li>
                <li className="md:w-3/12 w-1/2 md:px-4 px-2">
                  <Link className="md:hover:opacity-70 transition-opacity" to="/accessory/detail/?id=axe-firewood" onClick={() => setModalOpen(false)}>
                    <Image className="full-image" filename="common/accessory-cat-05.jpg" />
                  </Link>
                  <p className="futura mt-2">AXE & FIREWOOD</p>
                </li>
              </ul>
            </div>
            <div id="area" className="md:flex items-center mt-20">
              <div className="category-image bg-yellow">
                <span className="text-white text-2xl">モデル比較</span>
              </div>
              <p className="md:pl-12">様々なストーブの中からぴったりのストーブを選ぶ方法として、暖房面積やストーブのサイズでお選びいただく方法があります。<br />
                設置をご検討される室内の広さや環境と合わせてご検討ください。</p>
            </div>
            <div className="mt-12 md:px-6">
              <div className="search-area">
                <div className="title-block pb-6">
                  <p className="c-yellow md:text-3xl text-lg">最大暖房面積(㎡)から探す</p>
                  <p>※最大暖房面積は特定条件下におけるメーカーが算出した数値になり環境によって大きく変動しますのであらかじめご了承ください。</p>
                </div>
                <div className="item-block">
                  <div className="item-one">
                    <p className="value">210</p>
                    <hr />
                    <div className="item-list">
                      <p className="value">210㎡</p>
                      <Link to="/brand/morso/detail/?id=3640CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/3640CB.png" className="full-image" />
                        <p>3640CB</p>
                      </Link>
                      <Link to="/brand/morso/detail/?id=3610CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/3610CB.png" className="full-image" />
                        <p>3610CB</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one pc">
                    <p className="value" />
                    <hr />
                  </div>
                  <div className="item-one">
                    <p className="value">160</p>
                    <hr />
                    <div className="item-list">
                      <p className="value">160㎡</p>
                      <Link to="/brand/morso/detail/?id=1630CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/1630CB.png" className="full-image" />
                        <p>1630CB</p>
                      </Link>
                      <Link to="/brand/leda/detail/?id=ARUBA450" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/ARUBA450.png" className="full-image" />
                        <p>ARUBA450</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one pc">
                    <p className="value" />
                    <hr />
                  </div>
                  <div className="item-one">
                    <p className="value">150</p>
                    <hr />
                    <div className="item-list">
                      <p className="value">150㎡</p>
                      <Link to="/brand/morso/detail/?id=2B-ClassicCB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/2B.png" className="full-image" />
                        <p>2B-ClassicCB</p>
                      </Link>
                      <Link to="/brand/morso/detail/?id=1126CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/1126CB.png" className="full-image" />
                        <p>1126CB</p>
                      </Link>
                      <Link to="/brand/leda/detail/?id=ALLEGRA500" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/ALLEGRA500.png" className="full-image" />
                        <p>ALLEGRA500</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one pc">
                    <p className="value">140</p>
                    <hr />
                  </div>
                  <div className="item-one">
                    <p className="value" />
                    <hr />
                    <div className="item-list">
                      <p className="value">135㎡</p>
                      <Link to="/brand/morso/detail/?id=7110CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/7110CB.png" className="full-image" />
                        <p>7110CB</p>
                      </Link>
                      <Link to="/brand/morso/detail/?id=7140CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/7140CB.png" className="full-image" />
                        <p>7140CB</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one pc">
                    <p className="value">130</p>
                    <hr />
                  </div>
                  <div className="item-one">
                    <p className="value" />
                    <hr />
                    <div className="item-list">
                      <p className="value">126㎡</p>
                      <Link to="/brand/morso/detail/?id=2110CBUSA" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/2110CB.png" className="full-image" />
                        <p>2110CB<br />USA</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one two-column">
                    <p className="value">120</p>
                    <hr />
                    <div className="item-list">
                      <p className="value">120㎡</p>
                      <div className="row-wrap">
                        <div className="row">
                          <Link to="/brand/morso/detail/?id=8229CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/8229CB.png" className="full-image" />
                            <p>8229CB</p>
                          </Link>
                          <Link to="/brand/morso/detail/?id=7948CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/7948CB.png" className="full-image" />
                            <p>7948CB</p>
                          </Link>
                        </div>
                        <div className="row">
                          <Link to="/brand/morso/detail/?id=5660CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/5660CB.png" className="full-image" />
                            <p>5660CB</p>
                          </Link>
                          <Link to="/brand/morso/detail/?id=2140CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/2140CB.png" className="full-image" />
                            <p>2140CB</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3" />
                <div className="item-block">
                  <div className="item-one">
                    <p className="value">110</p>
                    <hr />
                    <div className="item-list">
                      <p className="value">110㎡</p>
                      <Link to="/brand/leda/detail/?id=ALLEGRA350" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/ALLEGRA350.png" className="full-image" />
                        <p>ALLEGRA350</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one">
                    <p className="value" />
                    <hr />
                    <div className="item-list">
                      <p className="value">105㎡</p>
                      <Link to="/brand/morso/detail/?id=3440CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/3440CB.png" className="full-image" />
                        <p>3440CB</p>
                      </Link>
                      <Link to="/brand/morso/detail/?id=7443CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/7443CB.png" className="full-image" />
                        <p>7443CB</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one two-column pc">
                    <p className="value">100</p>
                    <hr />
                  </div>
                  <div className="item-one two-column">
                    <p className="value">90</p>
                    <hr />
                    <div className="item-list">
                      <p className="value">90㎡</p>
                      <div className="row-wrap">
                        <div className="row">
                          <Link to="/brand/morso/detail/?id=2B-R-Standard-CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/2B-R.png" className="full-image" />
                            <p>2B-R<br />StandardCB</p>
                          </Link>
                          <Link to="/brand/morso/detail/?id=2B-C-Standard-CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/2B-C.png" className="full-image" />
                            <p>2B-C<br />StandardCB</p>
                          </Link>
                        </div>
                        <div className="row">
                          <Link to="/brand/morso/detail/?id=6140CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/6140CB.png" className="full-image" />
                            <p>6140CB</p>
                          </Link>
                          <Link to="/brand/morso/detail/?id=3142CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                            <Image filename="common/3142CB.png" className="full-image" />
                            <p>3142CB</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item-one two-column pc">
                    <p className="value">80</p>
                    <hr />
                  </div>
                  <div className="item-one">
                    <p className="value" />
                    <hr />
                    <div className="item-list">
                      <p className="value">75㎡</p>
                      <Link to="/brand/morso/detail/?id=1442CB " className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/1442CB.png" className="full-image" />
                        <p>1442CB </p>
                      </Link>
                      <Link to="/brand/morso/detail/?id=2840CB" className="item md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                        <Image filename="common/2840CB.png" className="full-image" />
                        <p>2840CB</p>
                      </Link>
                    </div>
                  </div>
                  <div className="item-one two-column">
                    <p className="value">70</p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:mt-36 mt-20 md:px-6">
              <div id="size" className="search-area">
                <div className="title-block">
                  <p className="c-yellow md:text-3xl text-lg">サイズから探す</p>
                </div>
                <div className="md:flex mt-4">
                  <div className="item-block-size">
                    <Link to="/brand/morso/detail/?id=7443CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/7443CB-s.jpg" />
                      <hr />
                      <p>7443</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=6140CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/6140CB-s.jpg" />
                      <hr />
                      <p>6140</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=2140CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/2140CB-s.jpg" />
                      <hr />
                      <p>2140</p>
                    </Link>
                  </div>
                  <div className="item-block-size">
                    <Link to="/brand/morso/detail/?id=1126CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/1126CB-s.jpg" />
                      <hr />
                      <p>1126</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=5660CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/5660CB-s.jpg" />
                      <hr />
                      <p>5660</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=3610CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/3610CB-s.jpg" />
                      <hr />
                      <p>3610/<br className="sp" />3640</p>
                    </Link>
                  </div>
                  <div className="item-block-size">
                    <div className="woman">
                      <Image className="full-image" filename="common/woman.png" />
                    </div>
                    <Link to="/brand/leda/detail/?id=ALLEGRA500" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/ALLEGRA500-s.jpg" />
                      <hr />
                      <p>ALLEGRA500</p>
                    </Link>
                    <Link to="/brand/leda/detail/?id=ALLEGRA350" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/ALLEGRA350-s.jpg" />
                      <hr />
                      <p>ALLEGRA350</p>
                    </Link>
                    <Link to="/brand/leda/detail/?id=ARUBA450" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/ARUBA450-s.jpg" />
                      <hr />
                      <p>ARUBA450</p>
                    </Link>
                  </div>
                </div>
                <div className="md:flex md:mt-20 mt-0">
                  <div className="item-block-size">
                    <Link to="/brand/morso/detail/?id=2B-ClassicCB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/2B-CB-s.jpg" />
                      <hr />
                      <p>2B-Classic</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=2B-R-Standard-CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/2B-R-CB-s.jpg" />
                      <hr />
                      <p>2B R/C</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=2840CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/2840CB-s.jpg" />
                      <hr />
                      <p>2840</p>
                    </Link>
                  </div>
                  <div className="item-block-size">
                    <Link to="/brand/morso/detail/?id=7110CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/7110CB-s.jpg" />
                      <hr />
                      <p>7110/<br className="sp" />7140</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=2110CBUSA" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/2110CB-s.jpg" />
                      <hr />
                      <p>2110</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=1630CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/1630CB-s.jpg" />
                      <hr />
                      <p>1630</p>
                    </Link>
                  </div>
                  <div className="item-block-size">
                    <div className="woman">
                      <Image className="full-image" filename="common/woman.png" />
                    </div>
                    <Link to="/brand/morso/detail/?id=1442CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/1442CB-s.jpg" />
                      <hr />
                      <p>1442</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=3440CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/3440CB-s.jpg" />
                      <hr />
                      <p>3440</p>
                    </Link>
                    <Link to="/brand/morso/detail/?id=3142CB" className="item-one md:hover:opacity-70 transition-opacity" onClick={() => setModalOpen(false)}>
                      <Image className="full-image" filename="common/3142CB-s.jpg" />
                      <hr />
                      <p>3142</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchModal;

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            ogImage
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaOgImage = `${site.siteMetadata.siteUrl}/img/${site.siteMetadata.ogImage}`;
  const defaultTitle = site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      <meta name="description" content={metaDescription} />
      <meta
        property="og:title"
        content={`${title} | ${site.siteMetadata.title}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={site.siteMetadata.title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:image" content={metaOgImage} />
      <link rel="shortcut icon" href="/img/favicon.ico" />
      <link rel="stylesheet" href="https://use.typekit.net/mty4vij.css" />
    </Helmet>
  );
}

export default SEO;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// 画像ファイルパスをプロパティに取るようなコンポーネントを定義
const Image = ({ filename, alt, width, className }) => (
  // ページじゃないコンポーネントでもGraphQLが使えるように
  // StaticQueryタグを使う
  <StaticQuery
    // GraphQLのクエリ引数には何も指定しない！
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 90)
              }
            }
          }
        }
      }
    `}
    // 全画像情報がdataに代入されている
    render={(data) => {
      // 指定した画像ファイルパス（コンポーネントのプロパティ）と
      // 一致するgatsby-image用の情報を取得

      // const image = data.images.edges.find((n) => n.node.relativePath.includes(filename));
      const image = data.images.edges.find((n) =>
        n.node.relativePath.includes(filename)
      );

      if (!image) {
        return null;
      }
      // Imgタグでgatsby-imageで最適化された画像を表示する
      const imageData = image.node.childImageSharp.gatsbyImageData;
      if (width) {
        return (
          <img
            // style={image.node.imageStyle}
            src={imageData.images.fallback.src}
            alt={`${alt || ''}`}
            className={`${className || ''}`}
            width={width}
          />
        );
      }
      // console.log(imageData)
      return <GatsbyImage image={imageData} alt={`${alt || ''}`} className={`${className || ''}`} />;
    }}
  />
);

export default Image;

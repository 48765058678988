import React from 'react';

const ListSns = () => (
    <div className="sns">
      {/* Facebook */}
      <a href="https://www.facebook.com/morso.jp" target="_blank" rel="noreferrer">
        <svg
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-5 h-5"
            viewBox="0 0 24 24"
        >
          <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
        </svg>
      </a>
      {/* Twitter */}
      <a href="https://twitter.com/AndersenStove" target="_blank" rel="noreferrer">
        <svg
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-5 h-5"
            viewBox="0 0 24 24"
        >
          <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
        </svg>
      </a>
      {/* Twitter */}
      <a href="https://www.instagram.com/andersenstove.morso.leda/" target="_blank" rel="noreferrer">
        <svg
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="w-5 h-5"
          viewBox="0 0 503.84 503.84"
        >
          <path d="M251.92,45.39c67.27,0,75.23,.26,101.8,1.47,24.56,1.12,37.9,5.22,46.78,8.67,11.76,4.57,20.15,10.03,28.97,18.84,8.82,8.82,14.28,17.21,18.84,28.97,3.45,8.88,7.55,22.22,8.67,46.78,1.21,26.56,1.47,34.53,1.47,101.8s-.26,75.23-1.47,101.8c-1.12,24.56-5.22,37.9-8.67,46.78-4.57,11.76-10.03,20.15-18.84,28.97-8.82,8.82-17.21,14.28-28.97,18.84-8.88,3.45-22.22,7.55-46.78,8.67-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67-11.76-4.57-20.15-10.03-28.97-18.84-8.82-8.82-14.28-17.21-18.84-28.97-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.56-1.47-34.53-1.47-101.8s.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78,4.57-11.76,10.03-20.15,18.84-28.97s17.21-14.28,28.97-18.84c8.88-3.45,22.22-7.55,46.78-8.67,26.56-1.21,34.53-1.47,101.8-1.47m0-45.39c-68.42,0-77,.29-103.87,1.52-26.81,1.22-45.13,5.48-61.15,11.71-16.57,6.44-30.62,15.05-44.62,29.06-14.01,14.01-22.62,28.05-29.06,44.62C7,102.93,2.74,121.24,1.52,148.05c-1.23,26.87-1.52,35.45-1.52,103.87s.29,77,1.52,103.87c1.22,26.81,5.48,45.13,11.71,61.15,6.44,16.57,15.05,30.62,29.06,44.62,14.01,14.01,28.05,22.62,44.62,29.06,16.02,6.23,34.34,10.49,61.15,11.71,26.87,1.23,35.45,1.52,103.87,1.52s77-.29,103.87-1.52c26.81-1.22,45.13-5.48,61.15-11.71,16.57-6.44,30.62-15.05,44.62-29.06,14.01-14.01,22.62-28.05,29.06-44.62,6.23-16.02,10.49-34.34,11.71-61.15,1.23-26.87,1.52-35.45,1.52-103.87s-.29-77-1.52-103.87c-1.22-26.81-5.48-45.13-11.71-61.15-6.44-16.57-15.05-30.62-29.06-44.62-14.01-14.01-28.05-22.62-44.62-29.06-16.02-6.23-34.34-10.49-61.15-11.71-26.87-1.23-35.45-1.52-103.87-1.52h0Z"/><path d="M251.92,122.56c-71.45,0-129.36,57.92-129.36,129.36s57.92,129.36,129.36,129.36,129.36-57.92,129.36-129.36-57.92-129.36-129.36-129.36Zm0,213.34c-46.38,0-83.97-37.6-83.97-83.97s37.6-83.97,83.97-83.97,83.97,37.6,83.97,83.97-37.6,83.97-83.97,83.97Z"/><circle cx="386.4" cy="117.44" r="30.23"/>
        </svg>
      </a>
    </div>
  );

export default ListSns;
